<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>
              Редактирование<br> шаблона id {{ config.id }}
            </div>
          </h1>
          <h1 v-else>
            <div>
              Создание шаблона для формирования аналитических справок
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">
            Дата создания<span>{{ config.createdDate | dateFormat }}</span>
          </div>
          <div v-if="config.id != null" class="creation-date">
            Дата изменения<span v-if="config.lastModifiedDate">{{ config.lastModifiedDate | dateFormat }}</span>
            <span v-else>—</span>
          </div>

          <h4>Название</h4>
          <v-text-field label="Название" v-model="config.content.title"></v-text-field>

          <h4 class="mt-2 mb-2">Файл</h4>
          <div>
            <v-row class="file-row align-center" v-if="config.content.attachment">
              <v-col cols="12" lg="4">
                <a @click="downloadAttachment(config.content.attachment)" download class="file-link">
                  <v-icon class="icon-item icon-file-document-outline"></v-icon>
                  {{ truncateStr(config.content.attachment.name, 50) }}
                </a>
              </v-col>
              <v-col cols="12" lg="5" class="d-flex align-center">
                <div class="delete ml-2" @click="deleteAttachment">
                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                </div>
              </v-col>
            </v-row>

            <input type="file" ref="attachmentInput" style="display: none" @change="addAttachment">
            <a @click="$refs['attachmentInput'].click()" class="configAdd" v-if="!config.content.attachment">+ добавить файл</a>
          </div>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/analyticalReport')">Отмена</v-btn>
            <v-btn :disabled="loading" v-if="config.id" outlined color="primary" @click="confirmToDelete">Удалить</v-btn>
            <v-btn :disabled="loading" color="primary" @click="submit">Сохранить</v-btn>
          </div>
        </div>
        <div v-if="errorText != null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {deleteDomainConfig, getDomainConfigById, saveDomainConfig, updateDomainConfig} from "@/modules/api.configs";
import {downloadFile, uploadFile} from "@/modules/api.files";

export default {
  name: "AnalyticalReportConfigEdit",
  props: {},
  components: {LoaderDialog},
  data() {
    return {
      domain: 'analytical_report',
      config: {
        id: null,
        content: {
          title: null,
          attachment: null,
        },
      },
      errorText: null,
      loading: false,
      apiLoaded: false,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mDate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mDate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async addAttachment() {
      this.loading = true
      let file = this.$refs['attachmentInput'].files[0]

      if (file) {
        let extension = file.name.split('.').pop()
        if (extension === 'docx') {
          const uploadedFile = await uploadFile(file)
          this.config.content.attachment = {
            id: uploadedFile.id,
            name: uploadedFile.name,
          }
        } else {
          alert('Расширение файла должно быть .docx')
        }
      }

      this.$refs['attachmentInput'].value = ""
      this.$forceUpdate()
      this.loading = false
    },
    async deleteAttachment() {
      this.config.content.attachment = null
      this.$forceUpdate()
    },
    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },

    validateConfig() {
      if (this.config.content.title == null || this.config.content.title < 1) {
        return "Заполните название шаблона"
      }
      if (this.config.content.attachment == null) {
        return "Прикрепите файл"
      }
      return true
    },

    async submit() {
      this.loading = true
      let validStat = this.validateConfig()
      if (validStat === true) {
        if (this.config.id === null) {
          let req = await saveDomainConfig(this.domain, this.config.content)
          if (req.ok) {
            await this.$router.push("/config/analyticalReport")
          } else {
            this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        } else {
          let req = await updateDomainConfig(this.domain, this.config.id, this.config.content)
          if (req.ok) {
            await this.$router.push("/config/analyticalReport")
          } else {
            this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        }
      } else {
        this.errorText = validStat
      }
      this.loading = false
    },
    async confirmToDelete() {
      let result = confirm('Вы уверены, что хотите удалить шаблон?');
      if (result) {
        this.loading = true
        let req = await deleteDomainConfig(this.domain, this.config.id)
        if (req.ok) {
          await this.$router.push("/config/analyticalReport")
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
      }
    },
    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },
  async beforeMount() {
    this.loading = true
    if (this.$route.params.id != null) {
      let req = await getDomainConfigById(this.domain, this.$route.params.id)
      if (req.ok) {
        this.config = req.payload
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    } else {
      this.apiLoaded = true
    }
    this.loading = false
  }
};
</script>
